import qs from "qs";
import Axios from "axios";
import Tools from "@/vendor/tools";
import { Toast } from "vant";
import adaptH5pFetch from "./adapt-h5p-fetch";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
// import {uploadFromWangSu} from "@/apis/verify";

export const fetchInstances = {
  http: null,
  uris: [],
};

//获取指纹信息
const getFingerprintId = async () => {
  const fpPromise = await FingerprintJS.load();
  const result = await fpPromise.get();  // 调用fpPromise.get()获取访问者指纹信息，并返回一个包含指纹ID和其他信息的结果对象。

  const { 
    hdr,//是否支持动态范围
    math,
    domBlockers,
    touchSupport,
    screenFrame,// 屏幕 可用区域外边距
    screenResolution,//屏幕分辨率
    openDatabase,
    reducedMotion,
    reducedTransparency,
    vendor,
    vendorFlavors,
    // webGlBasics,//webgl 基本信息
    // webGlExtensions,
    indexedDB,plugins,
    languages, 
    // audio, 
    //排除组件
    ...components } = result.components;

    //添加组件
    const addComponents ={
      ...components,
      ua: { value: navigator.userAgent },
    };

  const fingerprintId = FingerprintJS.hashComponents(addComponents);

  window.localStorage.setItem('VP-FingerprintJS-uuid', fingerprintId);
  // console.log('result.components==',addComponents);
  console.log('uuid==',fingerprintId);
  return fingerprintId;
}

/**
 *  1000: 服务器错误
 *
 *  1001: 返回值解析失败
 *
 *  1002: 请求未发出
 *
 *  1003: 前端请求逻辑错误
 */
const makeErr = (code, errMessage) => {
  return `请求失败,错误码:[${code}]` + (errMessage ? `错误信息:[${errMessage}]` : "");
};

const deResponse = (resStr) => {
  try {
    const re = JSON.parse(resStr);
    if (re) {
      return {
        ...re,
      };
    }
  } catch (error) {
    console.error(error, resStr);
    return {
      code: 0,
      msg: makeErr(1001),
    };
  }
};

export const initAxios = () => {
  fetchInstances.http = Axios.create({
    timeout: 20000,
    baseURL: process.env.NODE_ENV === "production" ? process.env.VUE_APP_BASE_URL : "/",
    withCredentials: true,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "device-model": "none", // 设备型号
      uuid: "none", // 设备唯一标识符
      "operating-system": "none", // 操作系统
    },
    paramsSerializer(params) {
      return qs.stringify(params);
    },
    // `transformRequest` 允许在向服务器发送前，修改请求数据
    // 只能用在 'PUT', 'POST' 和 'PATCH' 这几个请求方法
    // 后面数组中的函数必须返回一个字符串，或 ArrayBuffer，或 Stream
    transformRequest: [
      function (data /* headers */) {
        return qs.stringify(data);
      },
    ],
    // `transformResponse` 在传递给 then/catch 前，允许修改响应数据
    transformResponse: [
      function (data) {
        // 对 data 进行任意转换处理
        return data;
      },
    ],
  });

  fetchInstances.http.interceptors.response.use(
    (response) => {
      const {
        status, // 请求的状态码
        data,
      } = response;

      const { code, msg, result, systemDateTime, systemTimestamp } = deResponse(data);

      if (code === 401) {
        fetchInstances.http.vue.exitLogin();
      }

      if (code === 500) {
        msg = makeErr(1000);
      }

      return {
        ok: code == 200,
        status,
        data: result,
        code,
        msg,
        systemDateTime,
        systemTimestamp,
      };
    },
    (err) => {
      const response = (err || {}).response || {};
      const message = (err || {}).message || "";
      let { code, msg, result, systemDateTime, systemTimestamp } = deResponse(response.data || "");
      // console.log([err]);
      // console.error(message);

      if (code === 401) {
        fetchInstances.http.vue.exitLogin();
      }

      if (code === 500) {
        msg = makeErr(1000);
      }

      if (code == 0) {
        msg = makeErr(response.status || 1002, message);
      }

      return {
        ok: code == 200,
        status: response.status, // 请求的状态码
        data: result,
        code,
        msg,
        systemDateTime,
        systemTimestamp,
      };
    }
  );
};

export const setBaseUrl = (url) => {
  if (window.plus) adaptH5pFetch.setBaseUrl(url);
  fetchInstances.http.defaults.baseURL = url;
};
export const mountVueAtHttp = (vue) => (fetchInstances.http.__proto__.vue = vue);
export const setAuthentication = (token) => {
  if (window.plus) adaptH5pFetch.setToken(token);
  fetchInstances.http.defaults.headers["Authorization"] = token;
};
export const setDeviceInfo = async({ uuid, system, version, model }) => {
  if (window.plus) {
    adaptH5pFetch.setHeader("device-model", model);
    adaptH5pFetch.setHeader("uuid", uuid);
    adaptH5pFetch.setHeader("operating-system", system + " " + version);
    adaptH5pFetch.formatHeaders();
  }
  
  if (uuid) {
    fetchInstances.http.defaults.headers["device-model"] = model;
    fetchInstances.http.defaults.headers["uuid"] = uuid;
    fetchInstances.http.defaults.headers["operating-system"] = system + " " + version;
  } else {
    const rawStr = window.localStorage.getItem("UA");

    let FingerprintUUID = window.localStorage.getItem('VP-FingerprintJS-uuid');
    if( FingerprintUUID ){//如果本地存放了获取的指纹直接取本地uuid  没有则重新取
      fetchInstances.http.defaults.headers["uuid"] = FingerprintUUID;
    }else{
      fetchInstances.http.defaults.headers["uuid"] = await getFingerprintId();
    }

    try {
      const { os, device } = JSON.parse(rawStr);
      if (device)
        fetchInstances.http.defaults.headers["device-model"] = `${device.vendor} ${
          device.type || ""
        } ${device.model}`;
      if (os) fetchInstances.http.defaults.headers["operating-system"] = `${os.name} ${os.version}`;
    } catch (e) {
      // const deviceDetector = new DeviceDetector()
      // const { os, device } = deviceDetector.parse(window.navigator.appVersion || window.navigator.userAgent)
      // if (device) fetchInstances.http.defaults.headers['device-model'] = `${device.brand} ${device.type} ${device.model}`
      // if (os) fetchInstances.http.defaults.headers['operating-system'] = `${os.name} ${os.version}`
    }
  }
};

export const q = ({ url = "/", method = "get", data = {}, opts = {} } = {}) => {
  const requestOps = {
    url,
    method,
  };

  if (window.plus) {
    console.log("h5p 请求:", url);
    return new Promise((resolve) => {
      window.h5p.h5pFetch(
        { url, method, data },
        {
          ...opts,
          headers: adaptH5pFetch.getHeaders(),
          success: (status, text) => {
            const { code, msg, result, systemDateTime, systemTimestamp } = deResponse(text);
            if (code === 401) {
              fetchInstances.http.vue.exitLogin();
            }

            if (code === 500) {
              msg = ma;
            }
            resolve({
              ok: code === 200,
              status,
              data: result,
              code,
              msg,
              systemDateTime,
              systemTimestamp,
            });
          },
          fail: (e, msg, text) => {
            resolve({
              ok: false,
              code: 0,
              data: text,
              msg: msg || String(e),
            });
          },
        }
      );
    }).then((response) => {
      try {
        if (response.ok && (response.data.systemDateTime || response.data.systemTimestamp)) {
          const delay = response.data.systemTimestamp * 1000 - now;
          if (delay < fetchInstances.http.vue.netDelay) {
            fetchInstances.http.vue.netDelay = delay;
          }
        }
      } catch (error) {
        console.error(error);
      }
      return response;
    });
  }

  switch (method.toUpperCase()) {
    case "POST":
    case "PUT":
    case "PATCH":
      Object.assign(requestOps, { data: data });
      break;
    default:
      Object.assign(requestOps, { params: data });
      break;
  }

  Object.assign(requestOps, opts);
  const now = Date.now();
  return fetchInstances.http
    .request(requestOps)
    .then((res) => {
      // console.log('fetchInstances.http.vue', fetchInstances.http.vue);
      try {
        if (res.systemDateTime || res.systemTimestamp) {
          const delay = res.systemTimestamp * 1000 - now;
          // console.log(fetchInstances.http.vue.netDelay);
          if (delay < fetchInstances.http.vue.netDelay) {
            fetchInstances.http.vue.netDelay = delay;
          }
        }
      } catch (error) {
        console.error(error);
      }
      return res;
    })
    .catch((err) => {
      return err;
    });
};
export const f = (options) => {
  const _form = { ...options };
  Reflect.deleteProperty(_form, "url");
  const loadingHandler = Toast.loading({
    message: "上传中...",
    duration: 0,
  });

  Object.keys(_form).forEach((key) => {
    // 输出类型和值 
    console.log("上传接口:",key,typeof _form[key], _form[key]);
  })

  const html5plusUpload = () => {
    return new Promise((resolve) => {
      window.h5p.upload({
        url: fetchInstances.http.defaults.baseURL + options.url,
        filePath: options.imgFile,
        fileKey: "imgFile",
        options: {
          ..._form,
          imgFile: undefined,
        },
        headers: {
          Authorization: fetchInstances.http.defaults.headers["Authorization"],
          "device-model": fetchInstances.http.defaults.headers["device-model"],
          "operating-system": fetchInstances.http.defaults.headers["operating-system"],
          uuid: fetchInstances.http.defaults.headers["uuid"],
        },
        callback(ok, upload) {
          if (ok) {
            let { code, msg, result } = deResponse(upload.responseText);
            resolve({
              ok: code === 200,
              data: result,
              code,
              msg,
            });
          } else {
            resolve({
              ok,
              code: 500, // 请求的状态码
              data: "",
              msg: "app 上传图片失败",
            });
          }
        },
      });
    }).catch((e) => {
      Sentry.captureException(e, {
        tags: {
          capture_fn: "window.h5p.upload",
        },
      });
    });
  };

  const htmlUpload = (isRollback = false) => {
    const formData = new FormData();
    Object.keys(_form).forEach((key) => {
      if(isRollback && key === 'imgFile') return;
      else if(isRollback && key === '_file') {
        formData.append('imgFile', _form[key]);
        return
      } 
      else {
        formData.append(key, _form[key]);
      }
    });

    const postImage = () => {
      return new Promise((resolve) => {
        window.$.ajax({
          type: "post",
          url: fetchInstances.http.defaults.baseURL + options.url,
          data: formData,
          headers: {
            Authorization: fetchInstances.http.defaults.headers["Authorization"],
            "device-model": fetchInstances.http.defaults.headers["device-model"],
            "operating-system": fetchInstances.http.defaults.headers["operating-system"],
            uuid: fetchInstances.http.defaults.headers["uuid"],
          },
          cache: false,
          processData: false,
          contentType: false,
          success(data, status, xhr) {
            resolve({
              ok: data.code === 200,
              msg: data.msg,
              data: data.result,
              code: data.code,
            });
            console.log(data, status, xhr);
          },
          error(xhr, errorType, error) {
            resolve({
              ok: false,
              msg: `errorType:${errorType},${error && error.toString()}`,
              data: "",
              code: 500,
            });
            console.log(xhr, errorType, error);
          },
          complete(xhr, status) {
            console.log(xhr, status);
          },
        });
      });
    };

    return refetch(postImage, 3, 1000)
      .catch((err) => {
        Sentry.captureException(err, {
          tags: {
            capture_fn: "refetch(postImage",
          },
        });
      })
      .then((res) => {
        loadingHandler.clear();
        return res;
      });
  };

  if (window.plus) {
    return html5plusUpload().then((res) => {
      try {
        if (res.ok === false) {
          return htmlUpload(true);
        } else {
          loadingHandler.clear();
          return res;
        }
      } catch (error) {
        return res;
      }
    });
  } else {
    return htmlUpload();
  }
};

/**
 *
 * @param { Function } promise (index:number) => Promise<response>
 * @param { Number } length
 * @returns
 */
const refetch = (promise, length, ms = 100) => {
  let index = 0;
  return new Promise((resolve) => {
    const doFetch = () => {
      promise(index)
        .then((res) => {
          index += 1;
          if (res.ok) {
            resolve(res);
          } else {
            index >= length ? resolve(res) : setTimeout(doFetch, ms);
          }
        })
        .catch((err) => {
          resolve({ ok: false, message: makeErr(1003, err.message) });
        });
    };

    doFetch();
  });
};
