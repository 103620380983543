import { q } from "@/vendor/q";

// 获取加密货币信息
export const businessGetAccountInfo = (data) =>
  q({
    url: "/Api/User/Business/getAccountInfo",
    method: "POST",
    data,
  });

// 获取V币兑换USDT 汇率  type: IN（区块链购币） OUT （区块链卖币）
export const getRateInfo = (data) =>
  q({
    url: "/Api/User/Business/getRateInfo",
    method: "POST",
    data,
  });

  
// 发起V币兑换USDT订单  区块链卖币
export const businessChange = (data) =>
  q({
    url: "/Api/User/Business/businessChange",
    method: "POST",
    data,
  });


// 商家充值接口
export const businessDeposit = (data) =>
  q({
    url: "/Api/User/Business/businessDeposit",
    method: "POST",
    data,
  });

// 获取商家补贴配置信息
export const getBusinessConfig = (data) =>
  q({
    url: "/Api/User/Business/getBusinessConfig",
    method: "GET",
    data,
  });

// /Api/User/Business/applyBusiness 申请成为商家
export const applyBusiness = (data) =>
  q({
    url: "/Api/User/Business/applyBusiness",
    method: "POST",
    data,
  });

// /Api/Market/BusinessPendingOrder/marketPendingOrderList 商家挂单列表
export const marketPendingOrderList = (data) =>
  q({
    url: "/Api/Market/BusinessPendingOrder/marketPendingOrderList",
    method: "GET",
    data,
  });

// /Api/Market/BusinessPendingOrder/myPendingOrderList 我的挂单列表
export const myPendingOrderList = (data) =>
  q({
    url: "/Api/Market/BusinessPendingOrder/myPendingOrderList",
    method: "GET",
    data,
  });

// /Api/User/Business/getDepositDetail 商家充值详情
export const getDepositDetail = (data) =>
  q({
    url: "/Api/User/Business/getDepositDetail",
    method: "POST",
    data,
  });

// /Api/User/Business/getOrderDetail 商家充值详情
export const getOrderDetail = (data) =>
  q({
    url: "/Api/User/Business/getOrderDetail",
    method: "POST",
    data,
  });

// /Api/User/Business/marginInrerest 商家保证金领取
export const marginInrerest = (data) =>
  q({
    url: "/Api/User/Business/marginInrerest",
    method: "POST",
    data,
  });

// /Api/User/Business/getUserInfo 获取商家信息
export const getUserInfo = (data) =>
  q({
    url: "/Api/User/Business/getUserInfo",
    method: "POST",
    data,
  });

// /Api/Market/BusinessOrder/getOrderStatus 获取商家订单状态
export const getOrderStatus = (data) =>
  q({
    url: "/Api/Market/BusinessOrder/getOrderStatus",
    method: "GET",
    data,
  });

// /Api/User/Business/getOrderList 获取商家区块交易订单列表
export const getOrderList = (data) =>
  q({
    url: "/Api/User/Business/getOrderList",
    method: "POST",
    data,
  });
