<template>
  <div v-if="!invisible" class="install-global ios-bt">
    <div class="install-logo"></div>
    <div class="banners">
      <div class="topic">下载APP，体验{{$root.title}}钱包</div>
    </div>
    <a :href="$root.downloadAddress" class="install-btn" target="_blank"> 立即体验 </a>
    <div class="close-btn" @click="turnOffInSession"></div>
  </div>
</template>

<script>
export default {
  name: "Install",
  data() {
    return {
      invisible: false,
    };
  },
  created() {
    this.invisible = window.sessionStorage.getItem("install-invisible") == "1";
  },
  methods: {
    turnOffInSession() {
      window.sessionStorage.setItem("install-invisible", "1");
      this.invisible = true;
    },
  },
};
</script>

<style lang="scss">
.install-global {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #000;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  z-index: 15;

  .install-logo {
    height: 40px;
    width: 40px;
    background-image: url("@/assets/images/install-logo@2x.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .banners {
    // flex: 1;
    color: #fff;
    padding: 0 8px;
  }

  .topic {
    font-size: 15px;
  }

  .install-btn {
    width: 80px;
    height: 30px;
    background-color: #d90707;
    background-size: 100% 100%;
    color: #fff;
    font-size: 15px;
    border-radius: 15px;
    text-align: center;
    line-height: 30px;
  }

  .close-btn {
    position: absolute;
    top: -30px;
    right: 16px;
    height: 30px;
    width: 30px;
    background-image: url("@/assets/icons/close-circle@2x.png");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 20px 20px;
  }
}
</style>
