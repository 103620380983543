import Vue from "vue";
import Maintenance from "@/Maintenance.vue";
import {pickCdnByPing} from "@/vendor/getUrl";

export function createMaintenance(base, cfg) {
  const info = cfg.maintenance
  new Vue({
    data() {
      return {
        isH5Plus: !!window.plus,
        isKfSheetVisible: false,
        CDNurl: "",
        kfHtmlSrc: cfg.kfService
      }
    },
    render: (h) => h(Maintenance),
    created() {
      this.cdnSniffing(base, cfg)
      this.$dialog.alert({
        title: "系统维护",
        message: info.content,
      })
    },
    methods: {
      openCustomerService() {
        this.isKfSheetVisible = true
        if (this.isH5Plus) {
          //  this.cdnUrl + this.kfHtmlSrc
          let kfUrl = `plus/${this.kfHtmlSrc}.html`;
          let params = {
             kfServiceBackupUrl:this.config.kfServiceBackupUrl,
             kfServiceUrl:this.config.kfServiceUrl,
          }
          const fnClose = window.h5p.openCustomerService(kfUrl,params);
          this.$once('root:h5p:close-kf', () => {
            this.isKfSheetVisible = false
            fnClose()
          })
        }
      },
      cdnSniffing(base, cfg) {
        pickCdnByPing(base.image_cdn, cfg.image)
          .then(({url}) => this.CDNurl = url)
          .catch(e => {
            Sentry.captureException(e, {tags:{
              "capture_fn": "pickCdnByPing"
            }})
        })
      },
    }
  }).$mount('#app')
}
