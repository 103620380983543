import {f, q} from '@/vendor/q';

export const upload = data => f({
  url: '/Api/User/UploadFile/uploadImg',
  ...data
})


export const getConfig = data => q({
  url: '/Api/Common/Home/getConfig',
  method: 'GET',
  data
})

export const getNoticeList = data => q({
  url: '/Api/User/Notice/getNoticeList',
  method: 'GET',
  data
})

export const getAnnouncement = data => q({
  url: '/Api/User/Message/Announcement/index',
  method: 'GET',
  data
})

export const checkFundPassword = data => q({
  url: '/Api/User/Info/checkPassword',
  method: 'POST',
  data
})


export const getDeviceInfo = data => q({
  url: '/Api/User/Info/getDeviceInfo',
  method: 'GET',
  data
})
