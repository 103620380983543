import Axios from "axios";
import CryptoJS from "crypto-js";
import AES from "crypto-js/aes";
import MD5 from "crypto-js/md5";
import adaptH5pFetch from "./q/adapt-h5p-fetch";
import { fetchInstances } from "./q/instances";
import { dateFormat } from "./tools";

// https://f18e7dfe87113sf20e.s3.ap-east-1.amazonaws.com/dt060uksf.png  亚马孙
// https://storage.googleapis.com/f18e7dfe871131f20e/dt0s0uksf.png 谷歌
// https://f18e7dfe87113sf20e.ks3-sgp.ksyun.com/dt060uksf.png 金山
// 3个 拼接的方式是不一样的  应该要写3个规则

function deCryptogram(str, MD5_KEY) {
  // AES-256-ECB
  const bytes = AES.decrypt(str, CryptoJS.enc.Utf8.parse(MD5_KEY), {
    mode: CryptoJS.mode.ECB,
  });
  const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
  return decryptedData;
}

export function fetchApiUrl(refresh = false) {
  const time = dateFormat(new Date(), "YMDh");
  const titles = window.document.getElementsByTagName("title");
  const title = titles[0];
  const keys = title.config();
  const bktUrls = title.buckets(
    keys.map(({ dk, dp }) => ({
      key: MD5(time + dk)
        .toString()
        .substring(8, 26),
      png: dp,
    }))
  );
  let isFinished = false;
  let echoCounter = 0;
  return new Promise((resolve) => {
    const checkEcho = () => {
      if (isFinished) return;
      if (echoCounter >= bktUrls.length) {
        // 检查网络权限
        const isH5Plus = !!window.plus;
        if (isH5Plus) {
          const plus = window.plus;
          const networkStatus = plus.networkinfo.getCurrentType();
          const isConnected = (() => {
            switch (networkStatus) {
              case plus.networkinfo.CONNECTION_UNKNOW:
              case plus.networkinfo.CONNECTION_NONE:
                return false;
              case plus.networkinfo.CONNECTION_ETHERNET:
              case plus.networkinfo.CONNECTION_WIFI:
              case plus.networkinfo.CONNECTION_CELL2G:
              case plus.networkinfo.CONNECTION_CELL3G:
              case plus.networkinfo.CONNECTION_CELL4G:
              case plus.networkinfo.CONNECTION_CELL5G:
                return true;
              default:
                return false;
            }
          })();
          const noticeStr = isConnected
            ? `网络异常，请检查网络链接或网络权限是否开启！[${networkStatus}]`
            : `当前无网络![${networkStatus}]`;

          plus.nativeUI.confirm(
            noticeStr,
            (e) => {
              if (e.index === 0) plus.runtime.restart();
              if (e.index === 1) plus.runtime.quit();
            },
            { buttons: ["重启", "退出"] }
          );
        } else {
          window.alert(`bkt资源加载失败，请切换网络重新尝试`);
          location.reload();
        }
      }
    };

    const fetchBucket = (url, dk) => {
      (window.plus ? adaptH5pFetch.initFetch : Axios)
        .get(url)
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          if (!isFinished) {
            let decrypted;
            let isError = false;
            try {
              decrypted = deCryptogram(data, dk);
            } catch (error) {
              isError = true;
            }
            if (!isError && !!decrypted) {
              resolve(decrypted);
              isFinished = true;
              return decrypted;
            }
          }
        })
        .catch((err) => console.log("fetch bucket failed", err))
        .then(() => {
          console.warn("increase echo counter");
          echoCounter++;
          checkEcho();
        });
    };

    const run = () => {
      for (let index = 0; index < bktUrls.length; index++) {
        const element = bktUrls[index];
        fetchBucket(element, keys[index % keys.length].dk);
      }
    };

    run();
  });
}

export function pickUrlByPing(urls) {
  let isResolved = false;
  return new Promise((resolve, reject) => {
    let index = 0;
    const doFetch = (url) => {
      if (url) {
        (window.plus ? adaptH5pFetch.initFetch : Axios)
          .get(url + (url.endsWith("/") ? "" : "/") + "Api/Common/Home/getConfig")
          .then((response) => {
            if (response.status === 200) {
              fetchInstances.uris.push(url);
              if (!isResolved) {
                isResolved = true;
                resolve({ url, cfg: (response.data || {}).result });
              }
            }
          })
          .catch((e) => e)
          .then(() => {
            index += 1;
            if (index === urls.length && !isResolved) reject("all ping url failed");
          });
      }
    };
    for (const url of urls) {
      doFetch(url);
    }
  });
}

export function pickCdnByPing(urls, imageUrl) {
  return new Promise((resolve, reject) => {
    let index = 0;
    const doFetch = () => {
      if (index >= urls.length) return reject();
      const url = urls[index];
      if (url) {
        (window.plus ? adaptH5pFetch.initFetch : Axios)
          .get(url + (url.endsWith("/") ? "" : "/") + imageUrl)
          .then((response) => {
            if (response.status === 200) resolve({ url });
          })
          .catch((e) => e)
          .then(() => {
            index += 1;
            doFetch();
          });
      }
    };
    doFetch();
  });
}
