import { getConfig, getNoticeList } from "@/apis/common";
import { getUserInfo as getCryptoMerchantInfo } from "@/apis/crypto";
import {
  getBankInfo,
  getPaymentType,
  getUnreadCount,
  getUserInfo,
  QRCodeLoginCancel,
  QRCodeLoginConfirm,
  QRCodeLoginScan,
} from "@/apis/user";
import App from "@/App.vue";
import router from "@/router";
import { throttleReq } from "@/vendor/decorators";
import { PAYMENT_METHOD, STATUS_TYPE } from "@/vendor/enum";
import { pickCdnByPing } from "@/vendor/getUrl";
import { insertDx } from "@/vendor/init";
import { loadJsBase64, loadVueClipboards } from "@/vendor/lazyLoads";
import { setAuthentication } from "@/vendor/q";
import Tools from "@/vendor/tools";
import * as Sentry from "@sentry/vue";
import { Notify } from "vant";
import Vue from "vue";
import { $eventBus } from ".";

export function initVue({ schemaArguments, appVer, INITIAL_TOKEN }) {
  return new Vue({
    router,
    render: (h) => h(App),
    data() {
      return {
        initialized: false,
        isMerchantAlipayWechatOpen: false,
        waitingSellerCompletesOrderDelayTime: 0 /* cfg.market.waitingSellerCompletesOrderDelayTime */,
        waitingSellerConfirmDelayTime: 0 /* cfg.market.waitingSellerConfirmDelayTime */,
        buyerPaysOvertimeDelayTime: 0 /* cfg.market.buyerPaysOvertimeDelayTime */,
        appVersion: "x" /* cfg.appVersion */, // app 版本信息
        downloadAddress: "" /* cfg.downloadAddress */, // app download url
        app: null, // 顶级的web层
        appVer: appVer, // 应用ID
        schemaArguments, // 应用启动时传参
        isKfSheetVisible: false,
        kfHtmlSrc: "" /* cfg.kfService */,
        verifyCodeType: "" /* cfg.verifyCodeType */,
        isH5Plus: !!window.plus,
        title: process.env.VUE_APP_TITLE,
        CDNurl: "",
        netDelay: 100000,
        topBarDisplay: true,
        bottomBarDisplay: false,
        topIncludes: [],
        topConfig: null,
        userInfo: null,
        token: INITIAL_TOKEN,
        config: {},
        balance: {},
        banks: [],
        globalIdentifyDialogVisibility: false,
        title: "",
        showPassowordKeyboard: false,
        originalPayments: {
          bank: {
            type: "",
            typeName: "",
            list: [],
          },
          ali: {
            type: "",
            typeName: "",
            list: [],
          },
          wechat: {
            type: "",
            typeName: "",
            list: [],
          },
          usdt: {
            type: "",
            typeName: "",
            list: [],
          },
          digital: {
            type: "",
            typeName: "",
            list: [],
          },
        },
        unreadMessages: 0,
        showAnnouncementDialog: false,
        cryptoMerchantInfo: {
          userId: 0,
          interestSettlement: "0.0000",
          businessAssureBalance: "0.0000",
          businessBalance: "0.0000",
          level: {
            matchingLevel: null,
            feeRate: 0,
            userId: 0,
          },
        },
      };
    },
    computed: {
      payments() {
        const obj = {};
        for (const key in this.originalPayments) {
          const element = this.originalPayments[key];
          obj[key] = { ...element };
          obj[key].list = element.list.filter((item) => !item.isMerchant);
        }
        return obj;
      },
      merchantPayments() {
        const obj = {};
        for (const key in this.originalPayments) {
          const element = this.originalPayments[key];
          obj[key] = { ...element };
          obj[key].list = element.list.filter((item) => item.isMerchant);
        }
        return obj;
      },
      validPayments() {
        const map = {};
        Object.keys(this.originalPayments).forEach((key) => {
          const value = this.originalPayments[key];
          if (value.type) map[value.type] = value.list.length;
        });
        return map;
      },
      cdnUrl() {
        return this.CDNurl || this.config.image_cdn;
      },
      banksIdMappingName() {
        const map = {};
        if (this.banks && this.banks.length) {
          this.banks.forEach((item) => {
            map[item.bankId] = item.bankName;
          });
        }
        return map;
      },
      version() {
        return this.isH5Plus ? this.appVer : process.env.VUE_APP_VER;
      },
      isBindPwd() {
        return this.userInfo && this.userInfo.isEnteredPassword;
      },
      isBindPhone() {
        return this.userInfo ? this.userInfo.isEnteredPhoneNumber === 1 : true;
      },
      isSetUserName() {
        return this.userInfo ? this.userInfo.isUserName === 1 || !!this.userInfo.userName : true;
      },
      isBindAnyPayment() {
        return !Tools.isEmpty(this.validPayments);
      },
      waitingSellerCompleteOrderStr() {
        const mins = this.waitingSellerCompletesOrderDelayTime / 60;
        const reminder = mins % 60;
        const modular = (mins - reminder) / 60;
        if (mins < 60) return `${mins}分钟`;
        return `${modular}小时` + (reminder > 0 ? `${reminder}分钟` : "");
      },
      waitingSellerConfirmOrderStr() {
        const mins = this.waitingSellerConfirmDelayTime / 60;
        const reminder = mins % 60;
        const modular = (mins - reminder) / 60;
        if (mins < 60) return `${mins}分钟`;
        return `${modular}小时` + (reminder > 0 ? `${reminder}分钟` : "");
      },
      buyerPayingTimeOverStr() {
        const mins = this.buyerPaysOvertimeDelayTime / 60;
        const reminder = mins % 60;
        const modular = (mins - reminder) / 60;
        if (mins < 60) return `${mins}分钟`;
        return `${modular}小时` + (reminder > 0 ? `${reminder}分钟` : "");
      },
      registerMode() {
        return this.config ? this.config.userRegisterType : "2";
      },
      dynamicPasswordInputType() {
        const ua = window.navigator.userAgent.toLowerCase();
        if (ua.indexOf("huawei") > -1 || ua.indexOf("harmonyos") > -1) return "text";
        return "password";
      },
    },
    async created() {
      const vueClipboards = await loadVueClipboards();

      try {
        Vue.use(vueClipboards.default);
      } catch (error) {
        console.log("vueClipboards 挂载失败", error);
      }

      this.$on("root:set-topbar", (arr, config) => {
        this.topIncludes = arr;
        this.topConfig = config;
      });
      this.$on("root:set-layout", ({ top, bottom } = {}) => {
        if (Tools.isBoolean(top)) this.topBarDisplay = top;
        if (Tools.isBoolean(bottom)) this.bottomBarDisplay = bottom;
      });
      this.$on("root:set-title", (title) => {
        this.title = title;
      });
      this.$on("root:close-identify-dialog", () => {
        this.globalIdentifyDialogVisibility = false;
      });
    },
    methods: {
      cdnSniffing(base, cfg) {
        pickCdnByPing(base.image_cdn, cfg.image)
          .then(({ url }) => (this.CDNurl = url))
          .catch((e) => {
            Sentry.captureException(e, {
              tags: {
                capture_fn: "pickCdnByPing",
              },
            });
          });
      },
      openCustomerService() {
        this.isKfSheetVisible = true;
        if (this.isH5Plus) {
          //  this.cdnUrl + this.kfHtmlSrc
          // let kfUrl = `plus/${this.kfHtmlSrc}.html?kfServiceBackupUrl=${this.config.kfServiceBackupUrl}&kfServiceUrl=${this.config.kfServiceUrl}`
          // let kfUrl = `https://ez-demo-static.ubn-ele.com/kefu/mq_kefu.html`;
          let kfUrl = `plus/${this.kfHtmlSrc}.html`;
          let params = {
             kfServiceBackupUrl:this.config.kfServiceBackupUrl,
             kfServiceUrl:this.config.kfServiceUrl,
          }
          const fnClose = window.h5p.openCustomerService(kfUrl,params);
          this.$once("root:h5p:close-kf", () => {
            this.isKfSheetVisible = false;
            fnClose();
          });
        }
      },
      async processQrcodeResult(decodedText) {
        const toast = this.$toast.loading({
          message: "解析中",
          forbidClick: false,
          duration: 0,
        });
        if (!decodedText) {
          toast.clear();
          return this.$toast.fail("解析失败");
        }
        try {
          const { decode } = await loadJsBase64();
          const _decodeText = decode(decodedText.replace("ezpay://", ""));
          const [type, content] = _decodeText.split("?");
          toast.clear();
          if (type === "payOrder") {
            const [order, amount] = content.split("&");
            // 跳转支付页面
            if (!this.userInfo) return;
            return this.$router.push({
              path: "/pay_order",
              query: {
                orderNo: order.split("=")[1],
                amount: amount.split("=")[1],
              },
            });
          }
          if (type === "login") {
            const [, UUID] = content.split("=");
            // console.log(UUID);
            // 扫码登陆
            if (!this.userInfo) return;
            QRCodeLoginScan({ uuid: UUID }).then(({ ok, data, msg }) => {
              if (ok) {
                // console.log(JSON.stringify(data));
                this.$dialog.confirm({
                  title: "新设备登录确认",
                  message: `<p>
                                    您有新的设备:
                                    <span style="color: rgb(240, 83, 95);">
                                        ${data.deviceModel} ${data.operatingSystem}
                                    </span>
                                    将登录账号:
                                    <span style="color: rgb(240, 83, 95);">
                                        ${data.scannedUserNickname}
                                    </span>
                                    ,请确认是否登录新设备。
                                </p>`,
                  allowHtml: true,
                  confirmButtonText: "确认登录",
                  cancelButtonText: "取消登录",
                  confirmButtonColor: "#6f59fe",
                  beforeClose(action, done) {
                    if (action === "confirm") {
                      QRCodeLoginConfirm({ uuid: UUID }).then(({ ok, data, msg }) => {
                        if (ok) {
                          this.$toast.success(msg);
                        } else {
                          this.$toast.fail(msg);
                        }
                        done();
                      });
                    } else {
                      QRCodeLoginCancel({ uuid: UUID }).then(({ ok, data, msg }) => {
                        if (ok) {
                          this.$toast.success(msg);
                        } else {
                          this.$toast.fail(msg);
                        }
                        done();
                      });
                    }
                  },
                });
              } else {
                this.$toast.fail(msg);
              }
            });
            return;
          }
          await this.$dialog.alert({
            title: "扫描内容",
            message: `decode text:${_decodeText}`,
          });
        } catch (error) {
          toast.clear();
          console.error(error);
          Sentry.captureException(error);
        }
      },
      getHash(str = Math.random().toString().replace("0."), asString = true, seed = 0x811c9dc5) {
        var i,
          l,
          hval = seed;

        for (i = 0, l = str.length; i < l; i++) {
          hval ^= str.charCodeAt(i);
          hval += (hval << 1) + (hval << 4) + (hval << 7) + (hval << 8) + (hval << 24);
        }
        if (asString) {
          // Convert to 8 digit hex string
          return ("0000000" + (hval >>> 0).toString(16)).substr(-8);
        }
        return hval >>> 0;
      },
      async updateUserInfo() {
        const response = await this.fetchUserInfo();
        if (response.ok) {
          try {
            this.refreshGlobalNotices();
            this.loadConfig();
            this.loadBanks();
            this.loadPayments();
            this.updateUnReadCount().refresh();
          } catch (e) {
            Sentry.captureException(e, {
              tags: {
                capture_fn: "vueInstance.updateUserInfo catch",
              },
            });
          }
        }
        return response;
      },
      async fetchUserInfo() {
        const response = await getUserInfo();
        if (!response.ok) {
          this.$toast.fail(response.msg);
          await this.exitLogin();
          return response;
        }
        if (response.data.BusinessStatus === 1) {
          this.getCryptoMerchantInfo();
        }
        this.setUserInfo(response.data);
        this.$st.set("USER_INFO", response.data);
        return response;
      },
      setUserInfo(userInfo) {
        this.userInfo = userInfo;
        Sentry.setUser({
          id: userInfo.userId,
          username: userInfo.nickname,
        });
      },
      parsePayments(sum) {
        const payments = [ 16, 8, 4, 2, 1];
        const hits = [];
        payments.forEach((v) => {
          if (sum >= v) {
            hits.push(v);
            sum = sum - v;
          }
        });
        return hits;
      },
      copySuccessful() {
        this.$toast.success("复制成功");
      },
      loadConfig() {
        getConfig().then(({ ok, msg, data }) => {
          if (ok) {
            this.config = data;
          }
        });
      },
      loadBanks() {
        getBankInfo().then(({ ok, data, msg }) => {
          if (ok) {
            this.banks = data.list;
          } else {
            this.$toast.fail(msg);
          }
        });
      },
      loadPayments() {
        this.originalPayments = {
          bank: {
            type: "",
            typeName: "",
            list: [],
          },
          ali: {
            type: "",
            typeName: "",
            list: [],
          },
          wechat: {
            type: "",
            typeName: "",
            list: [],
          },
          usdt: {
            type: "",
            typeName: "",
            list: [],
          },
          digital: {
            type: "",
            typeName: "",
            list: [],
          },
        };
        return getPaymentType().then(({ ok, data, msg }) => {
          if (ok) {
            this.originalPayments = {
              bank: {
                type: "",
                typeName: "",
                list: [],
              },
              ali: {
                type: "",
                typeName: "",
                list: [],
              },
              wechat: {
                type: "",
                typeName: "",
                list: [],
              },
              usdt: {
                type: "",
                typeName: "",
                list: [],
              },
              digital: {
                type: "",
                typeName: "",
                list: [],
              },
            };
            data.list.forEach((item) => {
              const key = (() => {
                if (item.type == PAYMENT_METHOD.BANK) return "bank";
                if (item.type == PAYMENT_METHOD.ALI) return "ali";
                if (item.type == PAYMENT_METHOD.WE_CHAT) return "wechat";
                if (item.type == PAYMENT_METHOD.CRYPTO) return "usdt";
                if (item.type == PAYMENT_METHOD.DIGITAL) return "digital";
              })();
              this.originalPayments[key].type = item.type;
              this.originalPayments[key].typeName = item.typeName;
              try {
                this.originalPayments[key].list.push({
                  ...JSON.parse(item.info),
                  id: item.id,
                  isMerchant: item.ownerType === "Business",
                });
              } catch (e) {
                Sentry.captureException(e, {
                  tags: {
                    capture_fn: "get payment types failed",
                  },
                });
              }
            });
          }
          return { ok, data, msg };
        });
      },
      getServerTime() {
        return Date.now() + this.netDelay;
      },
      getImageUrl(imgUrl) {
        return imgUrl
          ? this.cdnUrl + ((this.cdnUrl || "").endsWith("/") ? "" : "/") + imgUrl.replace(/^\//, "")
          : "";
      },
      updateUnReadCount: throttleReq(function () {
        getUnreadCount().then(({ ok, data, msg }) => {
          if (ok) {
            if (this.isH5Plus)
              window.plus.runtime.setBadgeNumber(data.total, {
                title: `${this.title} 消息通知`,
                content: `您有${data.total}笔订单消息，请注意查收！`,
              });
            this.unreadMessages = data.total;
          }
        });
      }, 5 * 1000),
      async exitLogin() {
        clearTimeout(this.refreshGlobalNoticesTimer);
        setAuthentication(undefined);
        this.$st.remove("USER_INFO");
        this.$st.remove("TOKEN");
        this.token = null;
        $eventBus.$emit("root:exit-login");
        console.log("退出登录");
        await this.$router.replace("/layout_auth").catch((e) => {
          Sentry.captureException(e, {
            tags: {
              capture_fn: "exitLogin",
            },
          });
        });
      },
      // async disablePayments() {
      //   await this.$dialog.alert({
      //     title: '提示',
      //     message: `根据相关法律及政策规定，本平台“微信/支付宝”收付方式，近期将在相关部门审核通过后为用户开放。
      //               为此给您带来的不便深表歉意。`,
      //   })
      // },
      refreshGlobalNotices() {
        clearTimeout(this.refreshGlobalNoticesTimer);
        getNoticeList().then(({ ok, data, msg }) => {
          // console.log(data);
          if (ok) {
            this.runNotifications(data.list);
            this.runNotifications2(data.businessList || []);
            this.refreshGlobalNoticesTimer = setTimeout(() => {
              this.refreshGlobalNotices();
            }, 20 * 1000);
          }
        });
      },
      runNotifications(notifications) {
        clearTimeout(this.runNotificationsTimer);
        const len = notifications.length;
        let index = 0;
        const run = () => {
          if (index < len) {
            const item = notifications[index];
            // 当前页面
            if (this.$route.params.id == item.data.orderId) {
              index += 1;
              return run();
            }
            // 提示音
            if (this.isH5Plus) window.h5p.beep();

            Notify({
              type: ((v) => {
                if (v === 0) return "danger";
                if (v === 1) return "success";
                if (v === 2) return "primary";
                return "warning";
              })(STATUS_TYPE[item.type]),
              message: `${item.content}\n 订单编号:${item.data.orderNo}`,
              onClose: () => {
                this.runNotificationsTimer = setTimeout(() => run(), 2 * 1000);
              },
              onClick: () => {
                if (
                  this.userInfo.userId === item.data.buyerId &&
                  (item.data.buyerType === "Member" || item.data.buyerType === "Business")
                ) {
                  // 我是买家
                  if (
                    this.$route.path.indexOf("transaction_buy") < 0 &&
                    !this.$route.meta.rootPage
                  ) {
                    return this.$router.replace(`/transaction_buy/${item.data.orderId}`);
                  } else {
                    return this.$router.push(`/transaction_buy/${item.data.orderId}`);
                  }
                }
                if (
                  this.userInfo.userId === item.data.sellerId &&
                  (item.data.sellerType === "Member" || item.data.sellerType === "Business")
                ) {
                  // 我是卖家
                  if (
                    this.$route.path.indexOf("transaction_buy") < 0 &&
                    !this.$route.meta.rootPage
                  ) {
                    return this.$router.replace(`/transaction_sale/${item.data.orderId}`);
                  } else {
                    return this.$router.push(`/transaction_sale/${item.data.orderId}`);
                  }
                }
              },
            });
            index += 1;
          } else {
            clearTimeout(this.runNotificationsTimer);
          }
        };
        run();
      },
      runNotifications2(notifications) {
        clearTimeout(this.runNotificationsTimer2);
        const len = notifications.length;
        let index = 0;
        const run = () => {
          if (index < len) {
            const item = notifications[index];
            // 当前页面
            if (this.$route.params.id == item.data.orderId) {
              index += 1;
              return run();
            }
            // 提示音
            if (this.isH5Plus) window.h5p.beep();

            Notify({
              type: ((v) => {
                if (v === 0) return "danger";
                if (v === 1) return "success";
                if (v === 2) return "primary";
                return "warning";
              })(STATUS_TYPE[item.type]),
              message: `${item.content}\n 订单编号:${item.data.orderNo}`,
              onClose: () => {
                this.runNotificationsTimer2 = setTimeout(() => run(), 2 * 1000);
              },
              onClick: () => {
                if (
                  this.userInfo.userId === item.data.buyerId &&
                  (item.data.buyerType === "Member" || item.data.buyerType === "Business")
                ) {
                  /**
                   * 是否为商家交易流程，当用户为买家，且买家卖家都是商户时，跳转到商家交易
                   */
                  const isMerchantTrading =
                    this.$root.userInfo.BusinessStatus === 1 && item.data.sellerType === "Business";
                  const isNotTradingPage = this.$route.path.indexOf("transaction_buy") < 0;
                  const jumpWay = isNotTradingPage ? "push" : "replace";
                  // 我是买家
                  return this.$router[jumpWay](
                    `/transaction_buy/${item.data.orderId}?m=${isMerchantTrading ? 1 : 0}`
                  );
                }
                if (
                  this.userInfo.userId === item.data.sellerId &&
                  (item.data.sellerType === "Member" || item.data.sellerType === "Business")
                ) {
                  /**
                   * 是否为商家交易流程，当用户为卖家，且是商家时，跳转到商家交易
                   */
                  const isMerchantTrading = this.$root.userInfo.BusinessStatus === 1;
                  const isNotTradingPage = this.$route.path.indexOf("transaction_buy") < 0;
                  const jumpWay = isNotTradingPage ? "push" : "replace";
                  // 我是卖家
                  return this.$router[jumpWay](
                    `/transaction_sale/${item.data.orderId}?m=${isMerchantTrading ? 1 : 0}`
                  );
                }
              },
            });
            index += 1;
          } else {
            clearTimeout(this.runNotificationsTimer2);
          }
        };
        run();
      },
      // 获取商家信息
      async getCryptoMerchantInfo() {
        const { ok, data, msg } = await getCryptoMerchantInfo();
        if (ok) {
          this.cryptoMerchantInfo = data;
        } else {
          this.$toast.fail(msg);
        }
        return ok;
      },
    },
    mounted() {
      insertDx();
    },
  });
}
