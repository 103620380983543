import Vue from "vue";
import VueRouter from "vue-router";
import Components from "./components";
import Splash from "../views/splash.vue";
import { initSentry } from "../vendor/sentry";

Vue.use(VueRouter);

const routes = [
  {
    path: "",
    name: "layout",
    component: Components.layout,
    children: [
      {
        path: "/",
        name: process.env.VUE_APP_TITLE,
        component: Components.home,
        meta: {
          isHome: true,
          rootPage: true,
        },
      },
      {
        path: "/trade",
        redirect: "/trade/transaction",
        name: "交易中心",
        component: Components.layout_trading,
        meta: {
          identify: true,
          rootPage: true,
          isBindPayment: true,
        },
        children: [
          {
            path: "transaction",
            component: Components.transaction,
            name: "挂单市场",
          },
          {
            path: "crypto_trading",
            component: Components.crypto_trading,
            name: "区块链交易",
          },
          {
            path: "earn",
            component: Components.earn,
            name: "赚钱",
          },
          {
            path: "earn_agreement",
            component: Components.earn_agreement,
            name: "赚钱",
          },
        ],
      },
      {
        path: "/agreement_detail",
        name: "商家协议详情",
        meta: { identify: true },
        component: Components.agreement_detail,
      },
      {
        path: "/deposit_transfer",
        name: "保证金划转",
        component: Components.layout_deposit_transfer,
        meta: { identify: true },
        children: [
          {
            path: "deposit_transfer_in",
            component: Components.deposit_transfer_in,
            name: "保证金转入",
          },
          {
            path: "deposit_transfer_out",
            component: Components.deposit_transfer_out,
            name: "保证金转出",
          },
        ],
      },
      {
        path: "/trans_in_balance",
        name: "转入商家余额",
        component: Components.layout_trans_in_balance,
        meta: { identify: true },
        children: [
          {
            path: "trans_in_balance_from_blockchain",
            component: Components.trans_in_balance_from_blockchain,
            name: "商家余额充值",
          },
          // {
          //   path: "trans_in_balance_from_wallet",
          //   component: Components.trans_in_balance_from_wallet,
          //   name: `${process.env.VUE_APP_TITLE}钱包转入`,
          // },
        ],
      },
      {
        path: "/merchant_balance_transout",
        component: Components.merchant_balance_transout,
        name: "商家余额转出",
        meta: { identify: true },
      },
      {
        path: "/crypto_order_detail/:orderNo",
        component: Components.crypto_order_detail,
        name: "我的买单",
      },
      {
        path: "/orders/:index?",
        name: "我的订单",
        component: Components.orders,
        meta: {
          rootPage: true,
          customized: "orders",
        },
      },
      {
        path: "/payments/:type?",
        name: "收付款管理",
        component: Components.payments,
        meta: {
          rootPage: true,
        },
      },
      {
        path: "/add_payment/:index?",
        name: "添加收付款信息",
        component: Components.add_payment,
        meta: {
          identify: true,
          checkSecPwd: true,
        },
      },
      {
        path: "/sale",
        name: "出售V币",
        component: Components.sale,
        meta: {
          identify: true,
        },
      },
      {
        path: "/my_hang_orders",
        name: "我的挂单",
        component: Components.my_hang_orders,
      },
      {
        path: "/buy/:id",
        name: "购买V币",
        component: Components.buy,
        meta: {
          identify: true,
        },
      },
      {
        path: "/my_hang_order/:id",
        name: "我的挂单",
        component: Components.my_hang_order,
      },
      {
        path: "/transaction_sale/:id",
        name: "我的卖单",
        component: Components.transaction_sale,
      },
      {
        path: "/transaction_buy/:id",
        name: "我的买单",
        component: Components.transaction_buy,
      },
      {
        path: "/user",
        name: "用户中心",
        component: Components.user,
      },
      {
        path: "/user_bill",
        name: "个人帐变",
        component: Components.user_bill,
      },
      {
        path: "/user_bill_detail",
        name: "详情",
        component: Components.user_bill_detail,
      },
      {
        path: "/user_certify",
        name: "实名认证",
        component: Components.user_certify,
      },
      {
        path: "/user_credit",
        name: "信用等级",
        component: Components.user_credit,
      },
      {
        path: "/user_devices",
        name: "设备管理",
        component: Components.user_devices,
      },
      {
        path: "/user_login_psw",
        name: "修改登录密码",
        component: Components.user_login_psw,
      },
      {
        path: "/user_nickname",
        name: "修改昵称",
        component: Components.user_nickname,
      },
      {
        path: "/user_pay_psw",
        name: "修改资金密码",
        component: Components.user_pay_psw,
      },
      {
        path: "/user_report",
        name: "意见反馈",
        component: Components.user_report,
      },
      {
        path: "/panic",
        name: "异常交易行为及平台处理措施",
        component: Components.panic,
      },
      {
        path: "/user_us",
        name: "关于我们",
        component: Components.user_us,
      },
      {
        path: "/user_bind_phone",
        name: "绑定手机号",
        component: Components.user_bind_phone,
      },
      {
        path: "/user_security",
        name: "安全中心",
        component: Components.user_security,
      },
      {
        path: "/user_name",
        name: "用户名",
        component: Components.user_name,
      },
      {
        path: "/user_transfer",
        name: "个人转账",
        component: Components.user_transfer,
      },
      {
        path: "/bind_google_auth",
        name: "绑定谷歌验证器",
        component: Components.bind_google_auth,
      },
      {
        path: "/unbind_google_auth",
        name: "解绑谷歌验证器",
        component: Components.unbind_google_auth,
      },
      // 支付方式
      {
        path: "/banks",
        name: "银行卡",
        component: Components.banks,
      },
      {
        path: "/wechats",
        name: "微信",
        component: Components.wechats,
      },
      {
        path: "/alipays",
        name: "支付宝",
        component: Components.alipays,
      },
      {
        path: "/usdts",
        name: "USDT",
        component: Components.usdts,
      },
      {
        path: "/digitals",
        name: "数字人民币",
        component: Components.digitals,
      },

      {
        path: "/messages",
        name: "消息通知",
        component: Components.messages,
      },
      {
        path: "/msg_detail",
        name: "消息通知",
        component: Components.msg_detail,
      },
      {
        path: "/helps",
        name: "帮助中心",
        component: Components.helps,
      },
      {
        path: "/qr_login",
        name: "绑定设备",
        component: Components.qr_login,
        meta: {
          noAuth: true,
        },
      },
      {
        path: "/pay_order",
        name: "我的订单",
        component: Components.pay_order,
        meta: {
          identify: true,
          checkSecPwd: true,
        },
      },
      {
        path: "/how_to_save_receive_code/:isWechat?",
        component: Components.how_to_save_receive_code,
      },
      {
        path: "/rich_text/:id/:type",
        component: Components.rich_text,
      },
    ],
  },
  {
    path: "/layout_auth",
    name: "layout_auth",
    component: Components.layout_auth,
    children: [
      {
        path: "",
        name: "login",
        component: Components.login,
        meta: {
          noAuth: true,
        },
      },
      {
        path: "sign_in",
        name: "sign_in",
        component: Components.sign_in,
        meta: {
          noAuth: true,
        },
      },
    ],
  },
  {
    path: "/user_check",
    name: "user_check",
    component: Components.user_check,
    meta: { identify: true },
  },
  {
    path: "/splash",
    name: "splash",
    component: Splash,
    meta: {
      noAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

initSentry(Vue, router);

export default router;
