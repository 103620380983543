<template>
  <div id="app">
    <template v-if="this.$root.initialized">
      <router-view key="root" />
      <IdentifyDialog />
      <van-number-keyboard
        :show="$root.showPassowordKeyboard"
        safe-area-inset-bottom
        @blur="onBlur"
        @delete="onDelete"
        @input="onInput"
      >
        <van-password-input
          slot="title-left"
          :focused="$root.showPassowordKeyboard"
          :value="globalPassword"
          @focus="$root.showPassowordKeyboard = true"
        />
      </van-number-keyboard>
      <van-overlay :show="appScanPopupOverlay" z-index="5000" @click="closeAppScanPopupOverlay" />
      <SetPwd />
      <Install v-if="!$root.isH5Plus && !isWebViewApp && $root.downloadAddress" />
      <van-popup
        v-if="!$root.isH5Plus"
        v-model="$root.isKfSheetVisible"
        :style="{ height: '75%', minHeight: '75%', maxHeight: '75%', overflow: 'hidden' }"
        position="bottom"
      >
      <!--  oss :src="`${$root.CDNurl}${$root.kfHtmlSrc}`"  `https://ez-demo-static.ubn-ele.com/kefu/mq_kefu.html`  -->
        <iframe
          v-if="$root.isKfSheetVisible"
          :src="`/plus/${$root.kfHtmlSrc}.html?kfServiceBackupUrl=${$root.config.kfServiceBackupUrl}&kfServiceUrl=${$root.config.kfServiceUrl}`"
          style="
            width: 100%;
            position: absolute;
            top: 0;
            bottom: 0;
            height: -webkit-fill-available;
            /* max-height: 500px; */
          "
        >
          <h1
            style="
              text-shadow: 0 2px white;
              font-size: 1.5rem;
              font-weight: bold;
              color: #b8bec5;
              text-align: center;
            "
          >
            客服加载中。。。
          </h1>
        </iframe>
      </van-popup>
      <van-overlay
        v-else
        :show="$root.isKfSheetVisible"
        @click="$root.$emit('root:h5p:close-kf')"
      />
    </template>
    <Splash v-else />
  </div>
</template>

<script>
import Splash from "./views/splash.vue";
import IdentifyDialog from "@/components/IdentifyDialog.vue";
import SetPwd from "@/components/SetPwd.vue";
import Install from "@/components/Install.vue";
import { NumberKeyboard, PasswordInput } from "vant";
import { checkPC, checkInIframe } from "@/vendor/tools.js";

export default {
  components: {
    Splash,
    IdentifyDialog,
    SetPwd,
    Install,
    [NumberKeyboard.name]: NumberKeyboard,
    [PasswordInput.name]: PasswordInput,
  },
  data() {
    return {
      globalPassword: "",
      appScanPopupOverlay: false,
      isWebViewApp: true,
    };
  },
  watch: {
    $route(newRoute, oldRoute) {
      if (newRoute.path === oldRoute.path) return;
      this.globalPassword = "";
      this.$root.showPassowordKeyboard = false;
      this.$root.$emit("root:global-password", "close-by-route-change");
      if (checkInIframe()) {
        sessionStorage.setItem("RouterPathForPCIframe", "#" + newRoute.path); //保存地址
      }
      localStorage.setItem("updateRoutePath", "#" + newRoute.path);
    },
  },
  created() {
    localStorage.setItem('updateRoutePath', '#' + this.$route.path);
    const vipy = window.searchParams.get("vipy");
    this.$root.app = this;
    this.isWebViewApp = vipy === "1";
    if (this.isWebViewApp) document.body.setAttribute("webview", "");
  },
  mounted() {
    if (checkPC()) {
      //pc环境
      if (window.parent) {
        //子页面
        let iframeWrapper = window.parent.document.getElementById("PCIframeWrapper");
        let app = window.parent.document.getElementById("app");
        app.style.visibility = "hidden";
        app.style.display = "none";
        app.style.opacity = 0;
        app.style.height = 0;
        app.style.width = 0;
        app.style.position = "absolute";
        iframeWrapper.style.zIndex = 9999999999999;
        iframeWrapper.style.display = "block";
        let iframe = document.getElementById("PCIframe");
        iframe.setAttribute(
          "src",
          sessionStorage.getItem("RouterPathForPCIframe") ||
            localStorage.getItem("updateRoutePath") ||
            "#/"
        ); //设置地址
      }
    } else {
      let iframeWrapper = document.getElementById("PCIframeWrapper");
      iframeWrapper.innerHTML = "";
    }
  },
  methods: {
    onInput(value) {
      this.globalPassword += String(value);
      if (this.globalPassword.length === 6) {
        const globalPassword = String(this.globalPassword);
        this.globalPassword = "";
        this.$root.showPassowordKeyboard = false;
        this.$root.$emit("root:global-password", globalPassword);
      }
    },
    onBlur() {
      this.globalPassword = "";
      this.$root.showPassowordKeyboard = false;
      this.$root.$emit("root:global-password", false);
    },
    onDelete() {
      if (this.globalPassword.length) {
        this.globalPassword = this.globalPassword.slice(0, this.globalPassword.length - 1);
      }
    },
    closeAppScanPopupOverlay() {
      this.$root.$emit("root:close-app-scan-overlay");
    },
  },
};
</script>

<style lang="scss">
body {
  font-family: "PingFang SC", -apple-system, "Helvetica Neue", "Helvetica", "Arial", "Lucida Grande",
    sans-serif;
  font-weight: 400;
  color: $text_color;
  font-size: 15px;
  -ms-touch-action: none;
  /* IE10 */
  touch-action: none;
  /* IE11 */
}

.page {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  -ms-overflow-style: none;
}
</style>
