const lazyLoad = (load) => {
  let cache = undefined
  return async () => {
    if (cache) return cache
    try {
      cache = await load()
    } catch (error) {
      console.error(error);
      cache = {}
    }
    return cache
  }
}

export const loadAreaList = lazyLoad(() => import("@vant/area-data"));
export const loadJsBase64 = lazyLoad(() => import("js-base64"));
export const loadVueClipboards = lazyLoad(() => import("vue-clipboards"));
