const compareVr = (v1, v2) => {
  const v1arr = v1.split('.')
  const v2arr = v2.split('.')
  // if(v1arr.length != v2arr.length) {
  //     plus.nativeUI.alert(`更新包版本错误`, () => {
  //         force && plus.runtime.quit()
  //     }, "", "确认")
  //     throw new Error("更新包版本错误")
  // }
  const len = v1arr.length > v2arr.length ? v1arr.length : v2arr.length;
  for (let index = 0; index < len; index++) {
    const v1pos = v1arr[index] || 0;
    const v2pos = v2arr[index] || 0;
    if (Number(v1pos) > Number(v2pos)) {
      return 'big'
    }
    if (Number(v1pos < Number(v2pos))) {
      return 'small'
    }
  }

  return 'same'
}

window.compareVr = compareVr

export const checkUpdate = (appVersion, {
  currentVersion,
  minVersion,
  packageAddress,
  updatePackageAddress
}) => {
  try {
    if (window.plus) {
      // const cv = currentVersion
      const mv = minVersion
      const apv = appVersion
      // const lcv = plus.storage.getItem('downloadedCv') || ''

      // console.log(`cv:${cv} mv:${mv} apv:${apv} lcv:${lcv}`);

      function installHandle(isUpdated) {
        if (isUpdated) {
          plus.runtime.restart()
        } else {
          plus.nativeUI.alert(`更新包安装失败！`, () => {
            plus.runtime.quit()
          }, "", "确认")
        }
      }


      function update() {
        plus.nativeUI.showWaiting("下载安装中...");
        const slice = updatePackageAddress.split('/')
        slice.pop()
        slice.push(`${plus.runtime.appid}.wgt`)
        window.h5p.downWgt(slice.join('/'), function (isDownloaded, next) {
          if (isDownloaded) {
            // 强制更新逻辑
            next(isUpdated => installHandle(isUpdated))
          } else {
            plus.nativeUI.alert(`更新包下载失败！`, () => {
              plus.runtime.quit()
            }, "", "确认")
          }
        })
      }

      if (compareVr(apv, mv) === 'small') {
        update()
        return true
      }

      return false
    }
  } catch (error) {
    console.error("检测更新失败", error);
  }

}
