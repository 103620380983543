let headers = [];
const headersObj = {};

const getHeaders = () => headers;

const formatHeaders = () => {
  headers = [];
  Object.keys(headersObj).forEach((key) => {
    headers.push([key, headersObj[key]]);
  });
};

const setToken = (token) => {
  headersObj["Authorization"] = token || "";
  formatHeaders();
};
const setHeader = (k, v) => {
  headersObj[k] = v;
};
const setBaseUrl = (url) => {
  if (window.plus) {
    window.h5p.h5pFetch.baseUrl = String(url).replace(/\/$/gim, "");
  }
};

const initFetch = {
  get: (url) => {
    return new Promise((resolve, reject) => {
      window.h5p.h5pFetch(
        { url },
        {
          success: (status, text) => {
            let json = text;
            try {
              json = JSON.parse(text);
            } catch (error) {}
            if (status === 200) {
              resolve({
                status,
                data: json,
              });
            } else {
              reject({
                status,
              });
            }
          },
          fail: (e, msg) => {
            reject({
              status: 0,
            });
          },
        }
      );
    });
  },
};


export default {
  setBaseUrl,
  setToken,
  setHeader,
  getHeaders,
  formatHeaders,
  headers,
  initFetch,
};
