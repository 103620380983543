export const PAYMENT_METHOD = {
  ALI: 2,
  BANK: 1,
  WE_CHAT: 4,
  CRYPTO: 8,
  DIGITAL: 16,
};

// 挂单状态
export const HANG_ORDER_STAUTS = {
  INTRANSACTION: 1,
  HANGING: 2,
};

// 订单拆分状态
export const ORDER_SPLIT_STATUS = {
  ALLOW_SPLIT: 1,
  NOTALOOW_SPLIT: 0,
};

// 交易状态
// export const TRANSACTION_STATUS = {
//     DONE: 1,

// }

// 普通订单状态
export const ORDER_STATUS = {
  BUYER_REQUEST: "WaitingSellerConfirm",
  SELLER_CANCELED_DEAL: "SellerCancelsPurchase",
  WAITING_FOR_TRANSFER: "WaitingBuyerPay",
  BUYRER_CANCELD_DEAL: "BuyerNotPay",
  TIME_OVER: "BuyerPaymentExceedsTimeLimit",
  TRANSFERRED: "BuyerConfirmsOrderHasBeenPaid",
  SELLER_DID_NOT_RECEIVE: "SellerHasNotReceivedPayment",
  DISPUTING: "BuyerAppeals",
  CANCELED_BY_OFFICIAL: "AdminCancelsOrder",
  CONFIRMED_BY_OFFICIAL: "AdminCompletesOrder",
  DEAL: "SellerCompletesOrder",
  BUYER_NOT_APPEALS: "BuyerNotAppeals",
};

export const TRANSACTING_ORDER_STATUS = {
  // STEP 1
  HANGING: 1,
  BUYER_REQUEST: "WaitingSellerConfirm",
  // STEP 2
  SELLER_CANCELED_DEAL: "SellerCancelsPurchase",
  // STEP 3
  WAITING_FOR_TRANSFER: "WaitingBuyerPay",
  BUYRER_CANCELD_DEAL: "BuyerNotPay",
  TIME_OVER: "BuyerPaymentExceedsTimeLimit",
  // STEP 4
  TRANSFERRED: "BuyerConfirmsOrderHasBeenPaid",
  SELLER_DID_NOT_RECEIVE: "SellerHasNotReceivedPayment",
  DISPUTING: "BuyerAppeals",
  CANCELED_BY_OFFICIAL: "AdminCancelsOrder",
  CONFIRMED_BY_OFFICIAL: "AdminCompletesOrder",
  DEAL: "SellerCompletesOrder",
  BUYER_NOT_APPEALS: "BuyerNotAppeals",
};

export const STEP = {
  [TRANSACTING_ORDER_STATUS.HANGING]: 0,
  [TRANSACTING_ORDER_STATUS.BUYER_REQUEST]: 0,

  [TRANSACTING_ORDER_STATUS.SELLER_CANCELED_DEAL]: 1,

  [TRANSACTING_ORDER_STATUS.WAITING_FOR_TRANSFER]: 2,
  [TRANSACTING_ORDER_STATUS.BUYRER_CANCELD_DEAL]: 2,
  [TRANSACTING_ORDER_STATUS.TIME_OVER]: 2,

  [TRANSACTING_ORDER_STATUS.TRANSFERRED]: 3,
  [TRANSACTING_ORDER_STATUS.SELLER_DID_NOT_RECEIVE]: 3,
  [TRANSACTING_ORDER_STATUS.DISPUTING]: 3,
  [TRANSACTING_ORDER_STATUS.CANCELED_BY_OFFICIAL]: 3,
  [TRANSACTING_ORDER_STATUS.CONFIRMED_BY_OFFICIAL]: 3,
  [TRANSACTING_ORDER_STATUS.DEAL]: 3,
  [TRANSACTING_ORDER_STATUS.BUYER_NOT_APPEALS]: 3,
};

export const BUYER_STEP = {
  [TRANSACTING_ORDER_STATUS.HANGING]: 0,

  [TRANSACTING_ORDER_STATUS.BUYER_REQUEST]: 1,
  [TRANSACTING_ORDER_STATUS.SELLER_CANCELED_DEAL]: 1,

  [TRANSACTING_ORDER_STATUS.WAITING_FOR_TRANSFER]: 2,
  [TRANSACTING_ORDER_STATUS.BUYRER_CANCELD_DEAL]: 2,
  [TRANSACTING_ORDER_STATUS.TIME_OVER]: 2,

  [TRANSACTING_ORDER_STATUS.TRANSFERRED]: 3,
  [TRANSACTING_ORDER_STATUS.SELLER_DID_NOT_RECEIVE]: 3,
  [TRANSACTING_ORDER_STATUS.DISPUTING]: 3,
  [TRANSACTING_ORDER_STATUS.CANCELED_BY_OFFICIAL]: 3,
  [TRANSACTING_ORDER_STATUS.CONFIRMED_BY_OFFICIAL]: 3,
  [TRANSACTING_ORDER_STATUS.DEAL]: 3,
  [TRANSACTING_ORDER_STATUS.BUYER_NOT_APPEALS]: 3,
};

export const STATUS_TYPE = {
  // 积极状态
  [TRANSACTING_ORDER_STATUS.HANGING]: 1,
  [TRANSACTING_ORDER_STATUS.CONFIRMED_BY_OFFICIAL]: 1,
  [TRANSACTING_ORDER_STATUS.DEAL]: 1,

  // 消极状态
  [TRANSACTING_ORDER_STATUS.SELLER_CANCELED_DEAL]: 0,
  [TRANSACTING_ORDER_STATUS.BUYRER_CANCELD_DEAL]: 0,
  [TRANSACTING_ORDER_STATUS.SELLER_DID_NOT_RECEIVE]: 0,
  [TRANSACTING_ORDER_STATUS.DISPUTING]: 0,
  [TRANSACTING_ORDER_STATUS.CANCELED_BY_OFFICIAL]: 0,
  [TRANSACTING_ORDER_STATUS.TIME_OVER]: 0,
  [TRANSACTING_ORDER_STATUS.BUYER_NOT_APPEALS]: 0,

  // 普通状态
  [TRANSACTING_ORDER_STATUS.TRANSFERRED]: 2,
  [TRANSACTING_ORDER_STATUS.BUYER_REQUEST]: 2,
  [TRANSACTING_ORDER_STATUS.WAITING_FOR_TRANSFER]: 2,
};

/* `export const CRYPTO_WEB` 正在声明一个名为 `CRYPTO_WEB` 的常量变量并将其导出。该变量是一个包含字符串“USDT”和“TRX”的数组。 */
export const CRYPTO_WEB = ["USDT"];
