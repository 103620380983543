import Vue from 'vue';
import { amountFormat, dateFormat } from './tools';

import {
  HANG_ORDER_STAUTS,
  ORDER_SPLIT_STATUS,
  PAYMENT_METHOD,
  STEP,
  TRANSACTING_ORDER_STATUS
} from './enum';

// 支付方式
export function paymentMethod(val, type = 'class') {

  if (type == 'content') {
    if (val == PAYMENT_METHOD.ALI) return '支付宝'
    if (val == PAYMENT_METHOD.BANK) return '银行卡'
    if (val == PAYMENT_METHOD.WE_CHAT) return '微信'
  }

  if (val == PAYMENT_METHOD.ALI) return 'ali'
  if (val == PAYMENT_METHOD.BANK) return 'bank'
  if (val == PAYMENT_METHOD.WE_CHAT) return 'wechat'


}

// 挂单状态
export function hangOrderStatus(val, type = 'class') {

  if (type == 'content') {
    if (val == HANG_ORDER_STAUTS.INTRANSACTION) return '已发起交易'
    if (val == HANG_ORDER_STAUTS.HANGING) return '挂单中...'
  }

  if (val == HANG_ORDER_STAUTS.INTRANSACTION) return 'intransaction'
  if (val == HANG_ORDER_STAUTS.HANGING) return 'hanging'
}

// 拆分状态
export function orderSplitStatus(val, type = 'class') {
  if (type == 'content') {
    if (val == ORDER_SPLIT_STATUS.ALLOW_SPLIT) return '可拆分'
    if (val == ORDER_SPLIT_STATUS.NOTALOOW_SPLIT) return '不可拆分'
  }

  if (val == ORDER_SPLIT_STATUS.ALLOW_SPLIT) return 'split'
  if (val == ORDER_SPLIT_STATUS.NOTALOOW_SPLIT) return 'insplit'
}

// 交易记录订单状态
// export function transactionOrderStatus(val, type = 'class') {
//     if (type == 'content') {
//         if (val == TRANSACTION_STATUS.DONE) return '已完成'

//     }

//     if (val == TRANSACTION_STATUS.DONE) return 'done'
// }

// 订单状态
// export function orderStatus(val, type = 'class') {
//     if (type == 'content') {
//         if (val == ORDER_STATUS.DEAL) return '交易成功'
//         if (val == ORDER_STATUS.SELLER_CANCELED) return '卖家取消交易'
//         if (val == ORDER_STATUS.WAITING_BUYER_PAY) return '等待买家付款'
//         if (val == ORDER_STATUS.BUYER_CANCELD) return '买家取消交易'
//         if (val == ORDER_STATUS.BUYER_PAID) return '买家已付款'
//         if (val == ORDER_STATUS.SELLER_DIDNOT_RECEIVED) return '卖家未收到款'
//         if (val == ORDER_STATUS.CONFUSED_ORDER) return '争议订单'
//         if (val == ORDER_STATUS.PLATFORM_CANCELED) return '平台取消交易'
//         if (val == ORDER_STATUS.PLATFORM_CONFIRMED) return '平台确认交易'
//     }
//     if (val == ORDER_STATUS.DEAL) return 'deal'
//     if (val == ORDER_STATUS.SELLER_CANCELED) return 'seller-canceled'
//     if (val == ORDER_STATUS.WAITING_BUYER_PAY) return 'waiting-buyer-pay'
//     if (val == ORDER_STATUS.BUYER_CANCELD) return 'buyer-canceled'
//     if (val == ORDER_STATUS.BUYER_PAID) return 'buyer-paid'
//     if (val == ORDER_STATUS.SELLER_DIDNOT_RECEIVED) return 'seller-didnot-received'
//     if (val == ORDER_STATUS.CONFUSED_ORDER) return 'confuse-order'
//     if (val == ORDER_STATUS.PLATFORM_CANCELED) return 'platform-canceled'
//     if (val == ORDER_STATUS.PLATFORM_CONFIRMED) return 'platform-confirmed'
// }

// 交易订单状态
export function transactingOrderStatus(val, type = 'class', step = 0) {
  if (type == 'seller-content') {
    if (val == TRANSACTING_ORDER_STATUS.HANGING) return '挂单中...'
    if (val == TRANSACTING_ORDER_STATUS.BUYER_REQUEST) return '买家发起交易'

    if (val == TRANSACTING_ORDER_STATUS.SELLER_CANCELED_DEAL) return '卖家取消交易'

    if (val == TRANSACTING_ORDER_STATUS.WAITING_FOR_TRANSFER) return '等待买家转账'
    if (val == TRANSACTING_ORDER_STATUS.BUYRER_CANCELD_DEAL) return '买家取消交易'
    if (val == TRANSACTING_ORDER_STATUS.TIME_OVER) return '超时取消交易'

    if (val == TRANSACTING_ORDER_STATUS.TRANSFERRED) return '买家已转账'
    if (val == TRANSACTING_ORDER_STATUS.SELLER_DID_NOT_RECEIVE) return '卖家未收到款'
    if (val == TRANSACTING_ORDER_STATUS.DISPUTING) return '争议订单'
    if (val == TRANSACTING_ORDER_STATUS.CANCELED_BY_OFFICIAL) return '平台取消交易'
    if (val == TRANSACTING_ORDER_STATUS.CONFIRMED_BY_OFFICIAL) return '平台确认交易'
    if (val == TRANSACTING_ORDER_STATUS.DEAL) return '交易完成'

    if (val == TRANSACTING_ORDER_STATUS.BUYER_NOT_APPEALS) return '申诉期未申诉'

  }

  if (type == 'buyer-content') {
    if (val == TRANSACTING_ORDER_STATUS.HANGING) return '挂单中...'

    if (val == TRANSACTING_ORDER_STATUS.BUYER_REQUEST) return '请等待卖家确认'
    if (val == TRANSACTING_ORDER_STATUS.SELLER_CANCELED_DEAL) return '卖家取消交易'

    if (val == TRANSACTING_ORDER_STATUS.WAITING_FOR_TRANSFER) return '请买家转账'
    if (val == TRANSACTING_ORDER_STATUS.BUYRER_CANCELD_DEAL) return '买家取消交易'
    if (val == TRANSACTING_ORDER_STATUS.TIME_OVER) return '超时取消交易'

    if (val == TRANSACTING_ORDER_STATUS.TRANSFERRED) return '买家已转账'
    if (val == TRANSACTING_ORDER_STATUS.SELLER_DID_NOT_RECEIVE) return '卖家未收到款'
    if (val == TRANSACTING_ORDER_STATUS.DISPUTING) return '争议订单'
    if (val == TRANSACTING_ORDER_STATUS.CANCELED_BY_OFFICIAL) return '平台取消交易'
    if (val == TRANSACTING_ORDER_STATUS.CONFIRMED_BY_OFFICIAL) return '平台确认交易'
    if (val == TRANSACTING_ORDER_STATUS.DEAL) return '交易完成'

    if (val == TRANSACTING_ORDER_STATUS.BUYER_NOT_APPEALS) return '申诉期未申诉'

  }

  if (type == 'step') {
    if (step != 0) {
      const _step = STEP[val]
      if (_step > step && step == 1) return '已确认'
      if (_step > step && step == 2) return '已转账'

    }
    if (val == TRANSACTING_ORDER_STATUS.BUYER_REQUEST) return '等待确认'
    if (val == TRANSACTING_ORDER_STATUS.SELLER_CANCELED_DEAL) return '交易取消'

    if (val == TRANSACTING_ORDER_STATUS.WAITING_FOR_TRANSFER) return '等待转账'
    if (val == TRANSACTING_ORDER_STATUS.BUYRER_CANCELD_DEAL) return '取消交易'
    if (val == TRANSACTING_ORDER_STATUS.TIME_OVER) return '超时取消'

    if (val == TRANSACTING_ORDER_STATUS.TRANSFERRED) return '等待转币'
    if (val == TRANSACTING_ORDER_STATUS.SELLER_DID_NOT_RECEIVE) return '卖家未收到款'
    if (val == TRANSACTING_ORDER_STATUS.DISPUTING) return '争议订单'
    if (val == TRANSACTING_ORDER_STATUS.CANCELED_BY_OFFICIAL) return '平台取消交易'
    if (val == TRANSACTING_ORDER_STATUS.CONFIRMED_BY_OFFICIAL) return '平台确认交易'
    if (val == TRANSACTING_ORDER_STATUS.DEAL) return '确认转币'

    if (val == TRANSACTING_ORDER_STATUS.BUYER_NOT_APPEALS) return '申诉期未申诉'
  }


  if (step != 0) {
    const _step = STEP[val]
    if (_step > step && step == 1) return 'confirmed'
    if (_step > step && step == 2) return 'tranferred'

  }
  if (val == TRANSACTING_ORDER_STATUS.HANGING) return 'hanging'
  if (val == TRANSACTING_ORDER_STATUS.BUYER_REQUEST) return 'buyer-request'

  if (val == TRANSACTING_ORDER_STATUS.WAITING_FOR_CONFIRM_DEAL) return 'waiting-for-confirm-deal'
  if (val == TRANSACTING_ORDER_STATUS.CONFIRMED) return 'confirmed'
  if (val == TRANSACTING_ORDER_STATUS.SELLER_CANCELED_DEAL) return 'seller-canceled-deal'

  if (val == TRANSACTING_ORDER_STATUS.WAITING_FOR_TRANSFER) return 'waiting-for-transfer'
  if (val == TRANSACTING_ORDER_STATUS.TRANSFERRED) return 'tranferred'
  if (val == TRANSACTING_ORDER_STATUS.BUYRER_CANCELD_DEAL) return 'buyer-canceled-deal'
  if (val == TRANSACTING_ORDER_STATUS.TIME_OVER) return 'time-over'

  if (val == TRANSACTING_ORDER_STATUS.WAITING_TRANSFER) return 'waiting-transfer'
  if (val == TRANSACTING_ORDER_STATUS.CONFIRM_TRANSFER) return 'confirm-transfer'
  if (val == TRANSACTING_ORDER_STATUS.SELLER_DID_NOT_RECEIVE) return 'seller-did-not-receive'
  if (val == TRANSACTING_ORDER_STATUS.DISPUTING) return 'disputing'
  if (val == TRANSACTING_ORDER_STATUS.CANCELED_BY_OFFICIAL) return 'canceled-by-official'
  if (val == TRANSACTING_ORDER_STATUS.CONFIRMED_BY_OFFICIAL) return 'confirmed-by-official'
  if (val == TRANSACTING_ORDER_STATUS.DEAL) return 'deal'

  if (val == TRANSACTING_ORDER_STATUS.BUYER_NOT_APPEALS) return 'buyer-not-appeals'
}


Vue.filter('paymentMethod', paymentMethod)
Vue.filter('hangOrderStatus', hangOrderStatus)
Vue.filter('orderSplitStatus', orderSplitStatus)
// Vue.filter('transactionOrderStatus', transactionOrderStatus)
// Vue.filter('orderStatus', orderStatus)
Vue.filter('transactingOrderStatus', transactingOrderStatus)


Vue.filter('dateFormat', dateFormat)
Vue.filter('amountFormat', amountFormat)
Vue.filter('hide', (val, flag, def) => {
  if (flag && !val) {
    return def.replace(/[\d\D]/g, '*')
  }
  if (val && val.length > 0 && flag) {
    return val.replace(/[\d\D]/g, '*')
  }
  return val
})
Vue.filter('safeRealName', function (v = '') {
  if (v.length <= 1) return v
  const arr = v.split("")
  arr[1] = "*"
  return arr.join("")
})
Vue.filter('safeBankNumber', function (v) {
  return (v || '').replace(
    /^\d+(\d{4})$/g,
    ($1, $2) => `**** **** **** ${$2}`
  )
})
Vue.filter('safeText', function (v = '') {
  return v.split("").map((letter, index) => {
    if (index === 0 || index === v.length - 1) return letter
    return '*'
  }).join("")
})

Vue.filter('safeAddres', function (v = '') {
  return v.split("").map((letter, index) => {
    if (index < 4 || index > v.length - 5) return letter
    return '*'
  }).join("")
})
