/**
 *     <script src="https://cdn.dingxiang-inc.com/ctu-group/captcha-ui/index.js" crossorigin="anonymous"
 id="dx-captcha-script"></script>
 */
export const insertDx = () => {
  try {
    let script = document.createElement('script');
    script.setAttribute('src', 'https://cdn.dingxiang-inc.com/ctu-group/captcha-ui/index.js')
    script.setAttribute('crossorigin', 'anonymous')
    script.setAttribute('id', 'dx-captcha-script')
    document.body.appendChild(script)
  } catch (error) {
    console.error(`dx insert failed:`, error);
  }
}

export const appInitFailed = () => {
  plus.nativeUI.confirm("初始化失败！", e => {
    if (e.index === 0) {
      plus.runtime.restart()
    }
    if (e.index === 1) {
      plus.runtime.quit()
    }
  }, {
    buttons: [
      "重启", "退出"
    ]
  })
}
