export default {
  layout_auth: () => import("@/views/layout/auth.vue"),
  layout: () => import("@/views/layout/index.vue"),
  layout_trading: () => import("@/views/layout/trading.vue"),
  layout_deposit_transfer: () => import("@/views/layout/deposit_transfer.vue"),
  layout_trans_in_balance: () => import("@/views/layout/trans_in_balance.vue"),

  home: () => import("@/views/home/index.vue"),
  transaction: () => import("@/views/transaction/index.vue"),
  crypto_trading: () => import("@/views/crypto_trading/index.vue"),
  crypto_order_detail: () => import("@/views/crypto_order_detail/index.vue"),
  earn: () => import("@/views/earn/index.vue"),
  earn_agreement: () => import("@/views/earn_agreement/index.vue"),
  agreement_detail: () => import("@/views/earn_agreement/agreement_detail.vue"),
  deposit_transfer_in: () => import("@/views/deposit_transfer_in/index.vue"),
  deposit_transfer_out: () => import("@/views/deposit_transfer_out/index.vue"),
  trans_in_balance_from_blockchain: () =>
    import("@/views/trans_in_balance_from_blockchain/index.vue"),
  trans_in_balance_from_wallet: () => import("@/views/trans_in_balance_from_wallet/index.vue"),
  merchant_balance_transout: () => import("@/views/merchant_balance_transout/index.vue"),
  orders: () => import("@/views/orders/index.vue"),
  payments: () => import("@/views/payments/index.vue"),
  add_payment: () => import("@/views/add_payment/index.vue"),
  sale: () => import("@/views/sale/index.vue"),
  my_hang_orders: () => import("@/views/my_hang_orders/index.vue"),
  my_hang_order: () => import("@/views/my_hang_order/index.vue"),
  buy: () => import("@/views/buy/index.vue"),
  transaction_sale: () => import("@/views/transaction_sale/index.vue"),
  transaction_buy: () => import("@/views/transaction_buy/index.vue"),

  login: () => import("@/views/login/index.vue"),
  sign_in: () => import("@/views/sign_in/index.vue"),

  user: () => import("@/views/user/index.vue"),
  user_bill: () => import("@/views/user_bill/index.vue"),
  user_bill_detail: () => import("@/views/user_bill/detail.vue"),
  user_certify: () => import("@/views/user_certify/index.vue"),
  user_credit: () => import("@/views/user_credit/index.vue"),
  user_devices: () => import("@/views/user_devices/index.vue"),
  user_login_psw: () => import("@/views/user_login_psw/index.vue"),
  user_nickname: () => import("@/views/user_nickname/index.vue"),
  user_pay_psw: () => import("@/views/user_pay_psw/index.vue"),
  user_report: () => import("@/views/user_report/index.vue"),
  user_us: () => import("@/views/user_us/index.vue"),
  user_bind_phone: () => import("@/views/user_bind_phone/index.vue"),
  user_security: () => import("@/views/user_security/index.vue"),
  user_name: () => import("@/views/user_name/index.vue"),
  user_transfer: () => import("@/views/user_transfer/index.vue"),
  bind_google_auth: () => import("@/views/user_security/bind_google_auth.vue"),
  unbind_google_auth: () => import("@/views/user_security/unbind_google_auth.vue"),

  alipays: () => import("@/views/payments/alipays/index.vue"),
  wechats: () => import("@/views/payments/wechats/index.vue"),
  banks: () => import("@/views/payments/banks/index.vue"),
  usdts: () => import("@/views/payments/usdts/index.vue"),
  digitals: () => import("@/views/payments/digitals/index.vue"),

  panic: () => import("@/views/panic/index.vue"),
  helps: () => import("@/views/helps/index.vue"),
  qr_login: () => import("@/views/qr_login/index.vue"),
  messages: () => import("@/views/messages/index.vue"),
  msg_detail: () => import("@/views/messages/detail.vue"),

  pay_order: () => import("@/views/pay_order/index.vue"),

  how_to_save_receive_code: () => import("@/views/helps/how_to_save_receive_code.vue"),
  rich_text: () => import("@/views/rich_text/index.vue"),
  user_check: () => import("@/views/user_checking/index.vue"),
};
