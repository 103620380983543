export const throttleReq = (fn, ms) => {
  let lastTime = Date.now()
  return function callback(...args) {
    const that = this
    if (Date.now() - lastTime > ms) {
      lastTime = Date.now()
      fn.apply(this, args)
    }
    return {
      refresh() {
        lastTime = 0
        callback.apply(that, args)
      }
    }
  }
}
