import Vue from 'vue';
import {
  ActionSheet,
  Area,
  Button,
  Cell,
  CellGroup,
  Checkbox,
  CheckboxGroup,
  Circle,
  Collapse,
  CollapseItem,
  CountDown,
  Dialog,
  Divider,
  DropdownItem,
  DropdownMenu,
  Field,
  Form,
  Icon,
  Image,
  ImagePreview,
  List,
  Loading,
  NavBar,
  NoticeBar,
  Notify,
  Overlay,
  Picker,
  Popup,
  Progress,
  PullRefresh,
  Radio,
  RadioGroup,
  Step,
  Steps,
  Sticky,
  SwipeCell,
  Tab,
  Tabs,
  Uploader,
  Swipe,
  SwipeItem
} from 'vant'

Vue.use(Notify)
  .use(Picker)
  .use(Area)
  .use(Divider)
  .use(NoticeBar)
  .use(Dialog)
  .use(Popup)
  // .use(NumberKeyboard)
  .use(Image)
  .use(ImagePreview)
  .use(Field)
  .use(Form)
  .use(Loading)
  // .use(Grid)
  // .use(GridItem)
  .use(CountDown)
  .use(List)
  .use(PullRefresh)
  .use(SwipeCell)
  .use(Collapse)
  .use(CollapseItem)
  // .use(PasswordInput)
  .use(Overlay)
  .use(NavBar)
  // .use(Tabbar)
  // .use(TabbarItem)
  .use(DropdownMenu)
  .use(DropdownItem)
  .use(Cell)
  .use(CellGroup)
  .use(ActionSheet)
  // .use(Calendar)
  .use(Circle)
  .use(Progress)
  .use(Tabs)
  .use(Tab)
  .use(Button)
  .use(Radio)
  .use(RadioGroup)
  .use(Icon)
  .use(Uploader)
  .use(Checkbox)
  .use(CheckboxGroup)
  .use(Step)
  .use(Steps)
  .use(Sticky)
  .use(Swipe)
  .use(SwipeItem)
