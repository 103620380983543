<template>
  <div v-if="show" class="GlobalSetPwd">
    <van-dialog
      v-model="show"
      confirm-button-color="#6f59fe"
      confirm-button-text="去设置"
      show-cancel-button
      @cancel="onCancel"
      @confirm="onConfirm"
    >
      {{content}}
    </van-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      open: true
    }
  },
  computed: {
    content() {
      if(this.$root.registerMode === '1') {
        return "请设置用户名!"
      }
      if(this.$root.registerMode === '2') {
        return "请绑定手机号!"
      }
    },
    show: {
      get() {
        if(this.$root.registerMode === '1') {
          return this.$root.userInfo
            && this.$root.userInfo.isUserName !== 1
            && this.open
            && !window.sessionStorage.getItem("CLOSE_SET_USERNAME")
        }
        if(this.$root.registerMode === '2') {
          return this.$root.userInfo
            && this.$root.userInfo.isEnteredPhoneNumber !== 1
            && this.open
            && !window.sessionStorage.getItem("CLOSE_SET_USER_PHONE")
        }
      },
      set() {
      }
    }
  },
  methods: {
    onConfirm() {
      this.open = false
      if(this.$root.registerMode === '1') {
        this.$router.push("/user_name");
      }
      if(this.$root.registerMode === '2') {
        this.$router.push("/user_bind_phone");
      }
    },
    onCancel() {
      this.open = false
      if(this.$root.registerMode === '1') {
        window.sessionStorage.setItem("CLOSE_SET_USERNAME", 1);
      }
      if(this.$root.registerMode === '2') {
        window.sessionStorage.setItem("CLOSE_SET_USER_PHONE", 1);
      }
    },
  },
};
</script>

<style lang="scss">
.GlobalSetPwd {
  position: fixed;
  z-index: 99999999;
  background: #333333;

  .van-dialog__content {
    padding: 24px 0;
    text-align: center;
  }
}
</style>
