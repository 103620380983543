import { fetchInstances } from "./instances";

export { q, f, setAuthentication, mountVueAtHttp, setBaseUrl } from "./instances";

export const crypptoMerchantURL = (sellerType = "") => {
  if (fetchInstances.http.vue) {
    if (fetchInstances.http.vue.userInfo) {
      if (fetchInstances.http.vue.userInfo.BusinessStatus === 1) {
        if (sellerType === "Business") {
          // 卖家为商家，买家也为商家
          return "Business";
        } else {
          // 买家不为商家，买家为商家
          return "";
        }
      } else {
        if (sellerType === "Business") {
          // 卖家为商家，买家不为商家
          return "";
        } else {
          // 卖家不为商家，买家不为商家
          return "";
        }
      }
    }
  }
  return "";
};
