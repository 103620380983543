import * as Sentry from "@sentry/vue";

export const initSentry = (Vue, router) => {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    release: `${process.env.VUE_APP_VER}.${process.env.VUE_APP_ENV}`,
    environment:  (process.env.VUE_APP_ENV || process.env.NODE_ENV) + (process.env.VUE_APP_MODE ? "|app" : "") + `@${process.env.VUE_APP_VER}`,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.3,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    ignoreErrors: [
      //
      /Navigation aborted/,
      /Navigation cancelled/,
      /Avoided redundant navigation/,
      /\[vue-clipboards\] Invalid value\./,
      /window\.bannerNight/,
      /Redirected when going from/,
      /Non-Error promise rejection captured with value: cancel/,
      "登入已过期",
      /Non-Error promise rejection captured/
    ]
  });
};
